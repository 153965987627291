import React, { useContext } from 'react'
import CampingContext from '../../../contexts/campingData/campingData'
import { getVideosByIds, randomizeVideos } from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeCampingVideosByState = (
  selectedState,
  allCampingReviews,
  allVideos
) => {
  const campgroundsByState = allCampingReviews
    .filter(review => {
      if (review.categories.find(y => y === selectedState)) {
        return review
      }
    })
    .map(review => review.videoId)
  return randomizeVideos(getVideosByIds(campgroundsByState, allVideos)).slice(
    0,
    3
  )
}

const CampingVideosByState = ({ selectedState }) => {
  const allVideos = useContext(VideoContext)
  const allCampingReviews = useContext(CampingContext)
  return (
    <SuggestedVideos
      videos={computeCampingVideosByState(
        selectedState,
        allCampingReviews,
        allVideos
      )}
    />
  )
}

CampingVideosByState.propTypes = {
  selectedState: PropTypes.string,
}

export default CampingVideosByState
