import React, { useContext } from 'react'
import CampingContext from '../../../contexts/campingData/campingData'
import { getVideosByIds, randomizeVideos } from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'

const computeAllCampingVideos = (allCampingReviews, allVideos) => {
  return randomizeVideos(
    getVideosByIds(
      allCampingReviews.map(video => video.videoId),
      allVideos
    )
  ).slice(0, 3)
}

const AllCampingVideos = () => {
  const allVideos = useContext(VideoContext)
  const allCampingReviews = useContext(CampingContext)

  return (
    <SuggestedVideos
      videos={computeAllCampingVideos(allCampingReviews, allVideos)}
    />
  )
}

export default AllCampingVideos
